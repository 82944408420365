export const adjective = {
	singular: "lawyer",
	plural: "lawyers",
};

export const verb = {
	singular: "working",
};

export const job = {
	singular: "job",
	plural: "jobs",
};
